<template>
  <div class="xj-left-box3">
    <div class="xj-left-1">信件来源</div>
    <div @click="go">
      <div class="xj-left-2" id="zhangzhe">
        <!-- <div class="xj-left-2box">111</div> -->
      </div>
      <div class="xj-left-3">
        <div class="xj-left-3box">
          <div class="xj-left-3box-L xj-bg1">
            <div class="xj-box1">
              <span>{{ getdatavalue(156) }}</span>
              <span>%</span>
            </div>
            <div class="xj-box1">政府热线转办</div>
          </div>
          <div class="xj-left-3box-R xj-bg2">
            <div class="xj-box1">
              <span>{{ getdatavalue(157) }}</span>
              <span>%</span>
            </div>
            <div class="xj-box1">网格员上报</div>
          </div>
        </div>
        <div class="xj-left-3box">
          <div class="xj-left-3box-L xj-bg3">
            <div class="xj-box1">
              <span>{{ getdatavalue(158) }}</span>
              <span>%</span>
            </div>
            <div class="xj-box1">社区现场接访</div>
          </div>
          <div class="xj-left-3box-R xj-bg4">
            <div class="xj-box1">
              <span>{{ getdatavalue(159) }}</span>
              <span>%</span>
            </div>
            <div class="xj-box1">电话信访</div>
          </div>
        </div>
      </div>
      <!-- 2020年上报总数 -->
      <div class="xj-left-2box">
        <div class="xj-left-2box-1">
          <span>{{ getdatavalue(155) }}</span>
          <span>宗</span>
        </div>
        <div class="xj-left-2box-2">{{ getdatavalue(309) }}年上报总数</div>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "sftj",
        dataTyp: "sftj_xjly",
      },
      queryParams1: {
        dataModule: "sftj",
        dataTyp: "sftj_xjlytjt",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);

      this.zhangzhe();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "信件来源", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 民生警情环形
    zhangzhe() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("zhangzhe"));
        //    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
        // let xdata = ["政府热线转办", "网格员上报", "社区现场接访", "电话信访"];
        // let ydata = [
        //   {
        //     name: "政府热线转办",
        //     value: 400,
        //   },
        //   {
        //     name: "网格员上报",
        //     value: 200,
        //   },
        //   {
        //     name: "社区现场接访",
        //     value: 100,
        //   },
        //   {
        //     name: "电话信访",
        //     value: 20,
        //   },
        // ];
        let color = ["#0567E2", "#138887", "#7E7B75", "#889644"];
        // let color = ["#25F3E6", "#FF7F50", "#DA70D6"]
        // let color = ["#87CEFA", "#FF7F50", "#DA70D6"]
        //    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
        // let xdata = ["政府热线转办", "网格员上报", "社区现场接访", "电话信访"];

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "民生警情",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          // legend: {
          //   orient: "vertical",
          //   top: "1%",
          //   right: "8%",
          //   bottom: "0%",
          //   data: xdata,
          //   itemWidth: 10,
          //   itemHeight: 10,
          //   textStyle: {
          //     //图例文字的样式
          //     color: "#fff",
          //     fontSize: 14,
          //     padding: 1,
          //   },
          //   /*itemGap: 14,*/
          //   /*formatter:function(name){
          // 		  let oa = option.series[0].data;
          // 		  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
          // 		  for(let i = 0; i < option.series[0].data.length; i++){
          // 		      if(name==oa[i].name){
          // 		          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
          // 		      }
          // 		  }
          // 		}*/

          //   formatter: function (name) {
          //     return "" + name;
          //   },
          // },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["50%", "70%"],
              center: ["70%", "50%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },

              data: this.ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            if (params.name == this.ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          let dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("zhangzhe"));
        //    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
        // let xdata = ["政府热线转办", "网格员上报", "社区现场接访", "电话信访"];
        // let ydata = [
        //   {
        //     name: "政府热线转办",
        //     value: 400,
        //   },
        //   {
        //     name: "网格员上报",
        //     value: 200,
        //   },
        //   {
        //     name: "社区现场接访",
        //     value: 100,
        //   },
        //   {
        //     name: "电话信访",
        //     value: 20,
        //   },
        // ];
        let color = ["#0567E2", "#138887", "#7E7B75", "#889644"];
        // let color = ["#25F3E6", "#FF7F50", "#DA70D6"]
        // let color = ["#87CEFA", "#FF7F50", "#DA70D6"]
        //    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
        // let xdata = ["政府热线转办", "网格员上报", "社区现场接访", "电话信访"];

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "民生警情",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          // legend: {
          //   orient: "vertical",
          //   top: "1%",
          //   right: "8%",
          //   bottom: "0%",
          //   data: xdata,
          //   itemWidth: 10,
          //   itemHeight: 10,
          //   textStyle: {
          //     //图例文字的样式
          //     color: "#fff",
          //     fontSize: 14,
          //     padding: 1,
          //   },
          //   /*itemGap: 14,*/
          //   /*formatter:function(name){
          // 		  let oa = option.series[0].data;
          // 		  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
          // 		  for(let i = 0; i < option.series[0].data.length; i++){
          // 		      if(name==oa[i].name){
          // 		          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
          // 		      }
          // 		  }
          // 		}*/

          //   formatter: function (name) {
          //     return "" + name;
          //   },
          // },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["45%", "65%"],
              center: ["70%", "50%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 14,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 14,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },

              data: this.ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            if (params.name == this.ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          let dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    // this.zhangzhe();
    // this.drawChart();
  },
};
</script>

<style scoped lang="scss">
.xj-left-box3 {
  width: 100%;
  height: 51vh;
  overflow: hidden;
  //   border: 1px solid tan;
  position: relative;
  .xj-left-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .xj-left-2 {
    width: 100%;
    height: 22vh;
    // border: 1px solid teal;
    position: relative;
  }
  .xj-left-3 {
    width: 100%;
    height: 23vh;
    // border: 1px solid thistle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .xj-left-3box {
      width: 100%;
      height: 9.5vh;
      //   border: 1px solid thistle;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .xj-left-3box-L {
        width: 35%;
        height: 6.5vh;
        line-height: 3vh;
        padding-left: 1vw;
        // border: 1px solid thistle;
        color: #fff;
        .xj-box1 {
          font-size: 0.9vw;
          span {
            font-size: 0.8vw;
            &:first-child {
              font-family: hy;
              font-size: 1vw;
              font-style: italic;
              padding-right: 0.2vw;
            }
          }
        }
      }
      .xj-left-3box-R {
        width: 35%;
        height: 6.5vh;
        line-height: 3vh;
        padding-left: 1vw;
        // border: 1px solid thistle;
        color: #fff;
        .xj-box1 {
          font-size: 1vw;
          span {
            font-size: 0.8vw;
            &:first-child {
              font-family: hy;
              font-size: 1vw;
              font-style: italic;
              padding-right: 0.2vw;
            }
          }
        }
      }
      .xj-bg1 {
        background: #0567e2;
      }
      .xj-bg2 {
        background: #138887;
      }
      .xj-bg3 {
        background: #7e7b75;
      }
      .xj-bg4 {
        background: #889644;
      }
    }
  }
  .xj-left-2box {
    width: 50%;
    height: 10vh;
    // border: 1px solid teal;
    position: absolute;
    top: 11vh;
    left: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    .xj-left-2box-1 {
      span {
        &:first-child {
          font-size: 1.2vw;
          font-family: hy;
          font-weight: bold;
          font-style: italic;
          color: #ffd901;
          padding-right: 0.5vw;
        }
        &:last-child {
          font-size: 0.7vw;
          font-weight: bold;
          color: #ffd901;
        }
      }
    }
    .xj-left-2box-2 {
      font-size: 0.9vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #eeab1a;
      margin-top: 1.2vh;
    }
  }
}
</style>
