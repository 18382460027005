<template>
  <div class="numTb-box3">
    <div class="numTb-1">数量同比</div>
    <div class="numTb-2" id="numTb" @click="go"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "rkgk",
        dataTyp: "rkgk_zzfw",
      },
      queryParams1: {
        dataModule: "sftj",
        dataTyp: "sftj_sltb",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};

        this.ydata[i] = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);
      this.dataOptions1 = response.rows
      this.shijianFs();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions1.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "事件发生地", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 事件发生地
    shijianFs() {
      let myChart = this.$echarts.init(document.getElementById("numTb"));
      // 常住人口
      //   let dom = document.getElementById("numTb");
      //   let myChart = echarts.init(dom);
      let app = {};
      //   let option = null;
      let option = {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: this.xdata,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#fff",
              fontStyle: "normal",
              interval: 0,
              fontSize: 14,
            },
            //设置轴线的属性
            axisLine: {
              lineStyle: {
                color: "#0079FF",
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff",
              fontStyle: "normal",
              fontSize: 14,
            },
            //设置轴线的属性
            axisLine: {
              lineStyle: {
                color: "#0079FF",
                width: 1, //这里是为了突出显示加上的
              },
            },
            splitLine: {
              //网格线
              lineStyle: {
                color: "#666",
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              },
              show: true, //隐藏或显示
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "20%",
            data: this.ydata,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 14,
                  },
                },
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#83B1FF" }, //柱图渐变色
                  { offset: 0.5, color: "#83B1FF" }, //柱图渐变色
                  { offset: 0, color: "#04F3EE" }, //柱图渐变色
                ]),
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.numTb-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .numTb-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .numTb-2 {
    width: 100%;
    height: 39vh;
    // border: 1px solid teal;
  }
}
</style>
