<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">东泰社区司法调解汇总</div>
    <div @click="go">
      <div class="idx-middle-2">
        <div class="idx-middle-2box">
          <span>{{ getdatavalue(160) }}</span>
          <span>全部事件</span>
        </div>
        <div class="idx-middle-2box">
          <span>{{ getdatavalue(161) }}</span>
          <span>已处理</span>
        </div>
        <div class="idx-middle-2box">
          <span>{{ getdatavalue(162) }}</span>
          <span>处理中</span>
        </div>
        <div class="idx-middle-2box">
          <span>{{ getdatavalue(163) }}</span>
          <span>未批理</span>
        </div>
        <div class="idx-middle-2box">
          <span>{{ getdatavalue(164) }}</span>
          <span>处理率</span>
        </div>
      </div>
      <div class="idx-middle-3">
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(165) }}</span>
          <span>合同纠纷</span>
        </div>
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(166) }}</span>
          <span>劳资纠纷</span>
        </div>
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(167) }}</span>
          <span>邻里纠纷</span>
        </div>
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(168) }}</span>
          <span>物业纠纷</span>
        </div>
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(169) }}</span>
          <span>业委会纠纷</span>
        </div>
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(170) }}</span>
          <span>疫情防控纠纷</span>
        </div>
        <div class="idx-middle-3box">
          <span>{{ getdatavalue(171) }}</span>
          <span>其他纠纷</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sftj",
        dataTyp: "sftj_sftjhz",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "东泰社区司法调解汇总", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    width: 100%;
    height: 16vh;
    overflow: hidden;
    // border: 1px solid tan;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .idx-middle-2box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 2.8vh;
      span {
        &:first-child {
          font-size: 1.2vw;
          font-family: hy;
          font-weight: bold;
          font-style: italic;
          color: #ffd901;
        }
        &:last-child {
          font-size: 0.8vw;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #eeab1a;
        }
      }
    }
  }
  .idx-middle-3 {
    width: 100%;
    height: 23vh;
    overflow: hidden;
    // border: 1px solid tan;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // align-items: center;
    .idx-middle-3box {
      width: 14%;
      height: 7vh;
      line-height: 10vh;
      background: #01689b;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      line-height: 2.8vh;
      float: left;
      margin-left: 2.1vw;
      margin-bottom: 4.5vh;
      span {
        &:first-child {
          font-size: 1vw;
          font-family: hy;
          font-weight: bold;
          font-style: italic;
          color: #ffffff;
        }
        &:last-child {
          font-size: 0.8vw;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
